import styled from 'styled-components'
import theme, { media } from '../../helpers/theme'

const Layout = styled.div`
  padding: ${theme.space[8]}px;
  margin-top: ${theme.space[2]}px;
`

Layout.container = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  margin: 0 auto;
  height: 200px;
  justify-content: ${(props) =>
    props.noSubtitle ? 'flex-start' : 'space-evenly'};

  ${media(768)} {
    width: 750px;
  }

  ${media(992)} {
    width: 970px;
  }

  ${media(1200)} {
    width: 1170px;
  }
`

Layout.title = styled.div`
  font-size: ${(props) =>
    props.noSubtitle ? theme.font['4xl'] : theme.font['3xl']};
  color: ${theme.colors.font.lightgray};
  padding: ${(props) => (props.noSubtitle ? '0' : `0 ${theme.space[4]}px`)};
`

Layout.subtitle = styled.div`
  padding: ${theme.space[3]}px ${theme.space[4]}px;
  font-size: ${theme.font['xl']};
  color: ${theme.colors.font.gray};
`

Layout.button = styled.a`
  font-size: ${theme.font['base']};
  padding: ${theme.space[3]}px ${theme.space[2]}px;
  margin-top: ${theme.space[8]}px;
  background-color: ${theme.colors.font.teal};
  text-decoration: none;
  letter-spacing: 0.5px;
  white-space: nowrap;
  text-transform: uppercase;
  width: 200px;
  border-radius: 3px;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24);
  color: ${theme.colors.font.white};
  font-weight: 600;

  &:hover {
    cursor: pointer;
    background-color: #14b7ac;
  }

  &:active {
    transform: translateY(0.5px);
    background-color: #0f8981;
  }
`
Layout.popupContainer = styled.div`
  position: absolute;
  width: 100%;
  max-width: 400px;
  left:50%;
  transform:translateX(-50%);
  z-index:501;
  bottom: 95px;
`

export default Layout
