import styled, { keyframes } from 'styled-components'
import theme, { media } from '../../helpers/theme'
import Arrow from '../../images/Arrow-Drawn-Left.svg'

const Layout = styled.div`
  position: relative;
  background-image: ${(props) => `url(${props.imageSrc})`};
  background-repeat: no-repeat;
  width: 100%;
  justify-content: flex-start;
  transition: all 0.3 ease-in-out;
  height: 100vh;
  padding-top: calc(50vh - 10rem);
  background-size: cover;

  &:after {
    background: rgba(25, 28, 38, 0.7);
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }

  * {
    z-index: 100;
  }
`

Layout.content = styled.div`
  display: flex;
  flex-direction: column;
  color: ${theme.colors.white};
  text-align: center;
  align-items: center;
  padding: 0 ${theme.space[4]}px;
  padding-bottom: ${theme.space[5]}px;

  ${media(768)} {
    width: 750px;
    text-align: left;
    align-items: flex-start;
    margin: auto;
  }

  ${media(992)} {
    width: 970px;
  }

  ${media(1200)} {
    width: 1170px;
  }
`

Layout.title = styled.div`
  font-size: ${theme.font['4xl']};
  color: ${theme.colors.font.white};

  ${media('md')} {
    font-size: ${theme.font['5xl']};
  }
`

Layout.greenText = styled.span`
  color: ${theme.colors.font.teal};
`

Layout.subtitle = styled.div`
  padding-top: ${theme.space[6]}px;
  font-size: ${theme.font['base']};
  color: ${theme.colors.font.white};
  ${media('md')} {
    font-size: ${theme.font['2xl']};
  }
`

const fadeIn = keyframes`
0% {
  opacity: 0.3;
}

70% {
  opacity: 0.5;

}

100% {
  opacity: 1;
}
`

Layout.buttonContainer = styled.div`
  margin-top: ${theme.space[16]}px;
  display: flex;
  position: relative;

  ${media('xl')} {
    margin-left: ${theme.space[5]}px;
  }

  :after {
    position: absolute;
    width: 2.5rem;
    content: none;
    height: 2.5rem;
    bottom: 30px;
    right: -80px;

    ${media('md')} {
      content: url(${Arrow});
    }
  }
`

Layout.popupContainer = styled.div`
  position: relative;
  max-width: 400px;
  bottom:260px;


  @media (max-width:1023px){
    position:relative;
    width:400px;
    max-width:400px;
    left:0;
    transform:translateX(0)
  }

  @media (max-width:767px){
    position:relative;
    width:100%  ;
    max-width:400px;
    left: unset;
    top: unset;
    transform: translateX(0);
  }
`

Layout.button = styled.a`
  font-size: ${theme.font['base']};
  padding: ${theme.space[3]}px ${theme.space[12]}px;
  background-color: ${theme.colors.font.teal};
  align-items: center;
  text-decoration: none;
  letter-spacing: 0.5px;
  white-space: nowrap;
  text-transform: uppercase;
  font-weight: 400;
  border-radius: 3px;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24);
  color: ${theme.colors.font.white};
  font-family: 'Open Sans';

  :hover {
    cursor: pointer;
    background-color: #14b7ac;
  }

  &:active {
    transform: translateY(0.5px);
    animation-name: ${fadeIn};
    background-color: #0f8981;
  }

  ${media('xl')} {
    margin: 0 ${theme.space[8]}px;
  }
`

export default Layout
