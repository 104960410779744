import React, { useEffect, useState } from 'react'
import Layout from './home-hero.styles'
import { DataElementLocations } from '@eig-builder/core-utils/helpers/tagging-helper'
import HeroImage1 from '../../images/hero-1.jpg'
import HeroImage2 from '../../images/hero-2.jpg'
import HeroImage3 from '../../images/hero-3.jpg'
import PopPup from '../pop-pup'

const HomeHero = () => {
  const [selectedImage, setSelectedImage] = useState()
  const [callToAction, setCallToAction] = useState(false)
  const imageArray = [HeroImage1, HeroImage2, HeroImage3]

  useEffect(() => {
    setSelectedImage(imageArray[Math.floor(Math.random() * imageArray.length)])
    const interval = setInterval(() => {
      setSelectedImage(
        imageArray[Math.floor(Math.random() * imageArray.length)]
      )
    }, 120000)
    return () => clearInterval(interval)
  }, [imageArray])

  const redirectWebdotcom = (websiteUrl) => {
    setTimeout(() => {
      document.location.href = websiteUrl
      setCallToAction(false)
  }, 5000)
  }

  return (
    <Layout imageSrc={selectedImage}>
      <Layout.content>
        <Layout.title>
          Your <Layout.greenText>business</Layout.greenText> deserves to
          <br />
          be online
        </Layout.title>
        <Layout.subtitle>
          Find out what a website can do for you
        </Layout.subtitle>
        <Layout.buttonContainer>
          <Layout.button
            data-element-location={DataElementLocations.HERO}
            data-element-label='home.hero.button'
            data-element-id='sitelio-home-hero-button'
            onClick={() => {
              setCallToAction(true)
              redirectWebdotcom('https://www.web.com/websites/online-store-builder')
            }}
          >
            Create my website
          </Layout.button>
        </Layout.buttonContainer>
        <Layout.popupContainer>
          <PopPup callToAction={callToAction} setCallToAction={setCallToAction}/>
        </Layout.popupContainer>
      </Layout.content>
    </Layout>
  )
}

export default HomeHero
