import React from 'react'
import Steps from './home-steps.styles'
import InfoIcon from '../../components/info-icon'

const HomeSteps = () => {
  return (
    <Steps>
      <Steps.title>Getting started is simple, as easy as 1, 2, 3</Steps.title>
      <Steps.container>
        <InfoIcon
          icon='web'
          title='1. Choose a Theme'
          subtitle='Choose from our wide range of website designs.'
        />
        <InfoIcon
          icon='border_color'
          title='2. Edit your Website'
          subtitle='Add your own pages, content and images to your website.'
        />
        <InfoIcon
          icon='publish'
          title='3. Publish to the Web'
          subtitle="Your new site is ready to go live on the web. Let's launch it!"
        />
      </Steps.container>
    </Steps>
  )
}

export default HomeSteps
