import React from 'react'
import CTA from '../components/cta'
import HomeHero from '../components/home-hero'
import Layout from '../components/layout'
import HomeSteps from '../components/home-steps'
import ImageBanner from '../components/image-banner'
import HeroImage3 from '../images/hero-3.jpg'
import HeroImage2 from '../images/hero-2.jpg'
import SEO from '../components/seo'
import { HOME_TEXT } from '../helpers/constants'

const {
  EVERYTHING_YOU_NEED,
  MOBILE_READY,
  CTA_BUTTON,
  CTA_TITLE,
  CTA_SUBTITLE,
} = HOME_TEXT

const Home = () => {
  return (
    <Layout isWhiteLogo>
      <SEO title='Home' pageType='home' flowType='none' />
      <HomeHero />
      <ImageBanner title={EVERYTHING_YOU_NEED} imageSrc={HeroImage3} />
      <HomeSteps />
      <ImageBanner title={MOBILE_READY} imageSrc={HeroImage2} />
      <CTA
        title={CTA_TITLE}
        subtitle={CTA_SUBTITLE}
        button={CTA_BUTTON}
        label={'features'}
      />
    </Layout>
  )
}

export default Home
