import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { DataElementLocations } from '@eig-builder/core-utils/helpers/tagging-helper'
import Layout from './cta.styles'
import Popup from '../pop-pup'

const CTA = ({ title, subtitle, button, label }) => {
  const noSubtitle = subtitle === undefined
  const [callToAction, setCallToAction] = useState(false)
  const redirectWebdotcom = (websiteUrl) => {
    setTimeout(() => {
      document.location.href = websiteUrl
      setCallToAction(false)
  }, 5000)
  }

  return (
    <>
    <Layout>
      <Layout.container noSubtitle={noSubtitle}>
        <Layout.title noSubtitle={noSubtitle}>{title}</Layout.title>
        <Layout.subtitle>{subtitle}</Layout.subtitle>
        <Layout.button
          data-element-location={DataElementLocations.BODY}
          data-element-label={`CTA.${label}.button`}
          data-element-id={`sitelio-${label}-CTA-button`}
          onClick={() => {setCallToAction(true)
            redirectWebdotcom('https://www.web.com/websites/online-store-builder')}}
        >
          {button}
        </Layout.button>
      </Layout.container>
      <Layout.popupContainer>
        <Popup callToAction={callToAction} setCallToAction={setCallToAction} top={true} />
      </Layout.popupContainer>
    </Layout>
    </>
  )
}

CTA.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  label: PropTypes.string,
}

export default CTA
