import styled from 'styled-components'
import theme, { media } from '../../helpers/theme'

const Steps = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  margin: ${theme.space[8]}px 0;

  ${media('md')} {
    margin: ${theme.space[4]}px 0;
  }

  ${media(1200)} {
    height: 510px;
  }
`

const Title = styled.span`
  font-size: ${theme.font['4xl']};
  margin: ${theme.space[8]}px ${theme.space[2]}px;
  padding: 0 ${theme.space[4]}px;
  text-align: center;
  color: ${theme.colors.blackhighlight};
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;

  ${media(768)} {
    width: 750px;
    flex-direction: row;
  }

  ${media(992)} {
    width: 970px;
  }

  ${media(1200)} {
    width: 1170px;
  }
`

Steps.title = Title
Steps.container = Container
export default Steps
