export const HOME_TEXT = {
  EVERYTHING_YOU_NEED: "We've got everything you need to get online and grow",
  MOBILE_READY: 'Your new website is mobile ready, as standard',
  CTA_BUTTON: "LET'S GO!",
  CTA_TITLE: 'Ready to get started?',
  CTA_SUBTITLE: 'Find out what a website can do for you today',
}

export const FEATURE_TEXT = {
  FEATURE_HERO_TITLE: 'Features',
  FEATURE_HERO_SUBTITLE: "We've got everything you need to get online and grow",
  CTA_TITLE: 'Get Started Today',
  CTA_BUTTON: 'CREATE MY WEBSITE',
}

export const PRICING_TEXT = {
  CTA_TITLE: 'Get Started Today',
  CTA_BUTTON: 'CREATE MY WEBSITE',
}
